<!--
 * @Author       : JiangChao
 * @Date         : 2023-05-13 16:42:52
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-05-21 20:43:20
 * @Description  : 绑定手机号
-->
<template>
    <div class="container">
        <div class="bg" :style="{ minHeight: boxheight }">
            <div class="title"><span>綁定手機號碼</span></div>

            <div class="input-moudle">
                <van-field
                    class="input"
                    v-model="phone"
                    type="digit"
                    clearable
                    placeholder="請輸入手機號碼"
                >
                </van-field>
                <van-field
                    class="input"
                    v-model="smsCode"
                    type="digit"
                    v-if="show"
                    maxlength="6"
                    placeholder="請輸入簡訊驗證碼"
                >
                    <template #right-icon>
                        <div @click="$_getVerifyCode()" class="send">
                            <span class="line">|</span
                            >{{ counting ? `${timerCount}s` : "發送驗證碼" }}
                        </div>
                    </template>
                </van-field>
                <!-- <van-field
                    class="input"
                    v-model="email"
                    clearable
                    placeholder="請輸入電子郵箱"
                >
                </van-field>
                <van-field
                    class="input"
                    v-model="name"
                    clearable
                    placeholder="請輸入暱稱"
                >
                </van-field> -->
            </div>

            <div class="login-way">
                <div class="sms-way" @click="login">綁定手機號碼</div>
            </div>
        </div>
    </div>
</template>
<script>
import { handleErr } from "@/helpers";
import { Toast } from "vant";
// import { Toast } from 'vant';

export default {
    name: "",
    data() {
        return {
            smsCode: "",
            phone: "",
            show: false,
            timerCount: 0,
            counting: false, // 是否计时中
            yzBtnEnable: true,
            interval1: null,
            isLoading: false,
            appCode: "",
            email: "",
            name: "",
        };
    },
    mounted() {
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
        this.appCode = this.$route.params.appCode;
        // console.log('this.appCode---: ', this.appCode);
        // this.email = this.$route.params.email;
        // console.log('this.email : ', this.email );
        // this.name = this.$route.params.name;
        // console.log('this.name : ', this.name );
    },
    methods: {
        clearIntervalHandel() {
            clearInterval(this.interval1);
            this.yzBtnEnable = true;
            this.counting = false;
        },
        async $_getVerifyCode() {
            if (this.isLoading) return;
            if (!this.phone) {
                handleErr({ app: this.$parent, msg: "請輸入電話號碼" });
                return;
            }

            const nationCode = 886;
            const nationMobile = this.phone;
            this.isLoading = true;
            let timer = 60;
            try {
                this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    message: "加載中",
                    forbidClick: true,
                });
                const res = await this.$axios.get(
                    `/customer/mobile/code/${nationCode}/${nationMobile}`
                );
                const status = res.data.statusCode;
                if (status === 500) {
                    throw { msg: "系統繁忙，請稍後再試" };
                } else if (status === 200) {
                    this.$toast.clear();
                    this.interval1 = setInterval(() => {
                        timer -= 1;
                        if (timer <= 0) {
                            this.clearIntervalHandel();
                        } else {
                            this.timerCount = timer;
                            this.counting = true;
                            this.yzBtnEnable = false;
                        }
                    }, 1000);
                    throw { msg: "已送出驗證碼" };
                } else {
                    this.$toast.clear();
                    throw { msg: res.data.msg };
                }
            } catch (error) {
                this.$toast.clear();
                handleErr({ ...error, app: this.$parent });
            }
            this.isLoading = false;
        },

        async login() {
            if (!this.phone) {
                handleErr({ app: this.$parent, msg: "請輸入電話號碼" });
                return;
            }
            if (!this.smsCode && this.show) {
                handleErr({ app: this.$parent, msg: "請輸入簡訊驗證碼" });
                return;
            }
            // const nationCode = 886;
            // const nationMobile = this.phone;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
            try {
                const { data } = await this.$axios.post(
                    "/customer/loginByGoogleNewWeb",
                    {
                        email: this.email,
                        appCode: this.appCode,
                        name: this.name,
                        smsCode: this.smsCode,
                        nationMobile: this.phone,
                        type: "1", //为0是不需要绑定手机号，  为1是需要绑定手机号
                    }
                );
                // console.log("data: ", data);
                if (data.success) {
                    if (data.result.needSmsCode === "1") {
                        this.show = true;
                        Toast("請先獲取驗證碼");
                    } else {
                        const userToken = data.result.token;
                        const userMobile = data.result.mobile;
                        const email = data.result.email;
                        const avatar = data.result.avatar;
                        const nickName = data.result.name;
                        const id = data.result.userId;

                        // 手机号、id、邮箱、头像、昵称保存到localStorage里
                        localStorage.setItem("user_mobile", userMobile);
                        localStorage.setItem("id", id);
                        localStorage.setItem("email", email);
                        localStorage.setItem("avatar", avatar);
                        localStorage.setItem("nickName", nickName);

                        //token保存到cookies里
                        window.$cookies.set("token", userToken);
                        //保存到请求头
                        this.$axios.defaults.headers.common["token"] =
                            userToken;
                        this.$axios.defaults.headers.common["appType"] =
                            "PHONE";
                        this.show = false;
                        if (!email) {
                            this.$router.push("emailCheck");
                        } else {
                            this.$router.push("map");
                        }
                    }
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    props: {},
    components: {},
    computed: {
        boxheight() {
            return `${window.innerHeight}px`;
        },
    },
    created() {},
};
</script>

<style scoped lang="less">
.bg {
    text-align: center;
    .logo {
        width: 100px;
        margin-top: 60px;
        height: 100px;
    }
    .plug-inn {
        position: fixed;
        bottom: 0;
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        .other-way {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 20px;
            justify-content: space-around;
            color: #333333;
            font-weight: 500;
        }
    }
    .input-moudle {
        margin-right: 22px;
        margin-left: 22px;
        .input {
            margin-top: 24px;
            background: #f9f9f9;
            border-radius: 22px;
            margin-bottom: 7px;
            // position: static;
        }
        .line {
            opacity: 0.68;
            width: 1px;
            color: #e1e5e9;
            border-radius: 8px;
            margin-right: 20px;
        }
        .send {
            font-size: 14px;
            font-family: SourceHanSansCN, SourceHanSansCN-Normal;
            font-weight: Normal;
            text-align: center;
            color: #333333;
            line-height: 18px;
        }
    }
}
.title {
    background: #fee26c;
    text-align: center;
    padding: 10px;
}
.read-moudle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 22px;
    margin-top: 20px;
    .clause {
        font-size: 12px;
        font-family: SourceHanSansCN, SourceHanSansCN-Regular;
        font-weight: 400;
        text-align: left;
        color: #a1a1a1;
        line-height: 12px;
    }
    .read {
        font-size: 12px;
        font-family: SourceHanSansCN, SourceHanSansCN-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
        margin-left: 6px;
        line-height: 18px;
    }
}
.login-way {
    .sms-way {
        background: #2c394e;
        border-radius: 25px;
        height: 50px;
        color: #ffffff;
        line-height: 50px;
        margin: 20px;
    }
}
.g-signin-button {
    /* This is where you control how the button looks. Be creative! */
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    background-color: #3c82f7;
    color: #fff;
    height: 32px;
    width: 200px;
    line-height: 32px;
    margin-top: 20px;
    font-size: 14px;
}
</style>
